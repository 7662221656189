import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from "axios";
import NProgress from "nprogress";
import 'nprogress/nprogress.css'
import VueCookies from 'vue-cookies'
import VueClipboard from 'vue-clipboard2'

NProgress.configure({easing: 'ease', speed: 500, showSpinner: false})

axios.defaults.baseURL = 'https://api.linkedton.com/api/v1/'
// axios.defaults.baseURL = 'http://45.45.177.12:8081/api/v1/'
axios.interceptors.request.use(
    config => {
        NProgress.start()
        return config
    },
    (error) => {
        NProgress.done(); // 请求错误时完成进度条
        return Promise.reject(error);
    })
axios.interceptors.response.use(
    config => {
        NProgress.done()
        return config
    },
    (error) => {
        NProgress.done(); // 响应错误时完成进度条
        return Promise.reject(error);
    })
Vue.prototype.$axios = axios
Vue.use(VueClipboard)
Vue.use(Vant)
Vue.use(VueCookies)
Vue.config.productionTip = false

// NProgress.start()
// window.addEventListener('onload', ()=>{
//   NProgress.done()
//   NProgress.remove()
// });

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
