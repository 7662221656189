<template>
  <main>
    <span class="checkout-title">Checkout</span>
    <div class="section-content">
      <div class="section-img">
        <svg xmlns="http://www.w3.org/2000/svg" height="6em" viewBox="0 0 512 512">
          <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
              fill="#ff561a"
              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
        </svg>
      </div>
      <span class="section-label">Success</span>
    </div>

    <div class="bottom-box">
      <van-button block @click="ToUse" class="confirm">Back to Home</van-button>
    </div>
  </main>
</template>

<script>
import {Toast} from "vant";

export default {
  name: "CheckoutPage",
  data() {
    return {
      session_id: '',
      token: '',
      device: ''
    }
  },
  created() {
    // 获取用户代理字符串
    const userAgent = navigator.userAgent;
    // 判断设备类型
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.device = 'iOS';
    } else if (/Android/.test(userAgent)) {
      this.device = 'Android';
    } else if (window.chrome && window.chrome.webview) {
      this.device = 'Windows';
    } else {
      this.device = 'Unknown';
    }
    const token = this.$cookies.get('token')
    if (token != null)
      this.token = token
    this.session_id = this.$route.query.session_id
    this.saveSession()
  },
  methods: {
    ToUse() {
      if (this.device === 'iOS') {
        //vue调用iOS方法，且传值给iOS （iOS 方法名为 getDataFormVue）
        window.webkit.messageHandlers.JsBridge.postMessage({
          action: 'toHome'
        });
      } else if (this.device === 'Android') {
        //vue调用Android方法，且传值给Android （Android方法名为 getDataFormVue）
        window.Android.toHome()
      } else if (this.device === 'Windows') {
        window.chrome.webview.postMessage("{'action':'home'}");
      } else if (this.device === 'Unknown') {
        const parentUrl = document.referrer;
        window.parent.location.href = parentUrl + "dashboard/download";
      }
    },
    saveSession() {
      if (this.session_id === undefined) return
      if (this.token == null) return;
      if (this.device === 'iOS') {
        //vue调用iOS方法，且传值给iOS （iOS 方法名为 getDataFormVue）
        window.webkit.messageHandlers.JsBridge.postMessage({
          action: 'success'
        });
      } else if (this.device === 'Android') {
        //vue调用Android方法，且传值给Android （Android方法名为 getDataFormVue）
        window.Android.success()
      } else if (this.device === 'Windows') {
        window.chrome.webview.postMessage("{'action':'success'}");
      }
      const toast = Toast.loading({message: 'loading....', forbidClick: true});
      const this_vue = this;
      this.$axios.post('user/savesession',
          {sessionId: this.session_id},
          {
            headers: {'Authorization': this.token, 'Content-type': 'application/x-www-form-urlencoded'}
          })
          .then(function () {
            toast.clear()
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })

    },
    showError(error) {
      if (typeof (error.response) == 'undefined')
        Toast.fail({message: error.message, duration: 3000})
      else
        Toast.fail({message: error.response.data.message, duration: 3000})
    },
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:white;')
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  }
}
</script>

<style scoped>
body {
  position: relative;
  height: 100vh;
  margin: 0;
}

.checkout-title {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  font-size: 24px;
}

.confirm {

  background: #ff561a;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  width: 90%;
  display: flex;
  justify-content: center;
}

.section-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-img {
  display: flex;
  justify-content: center;
  padding: 30px 0px;
}

.section-label {
  font-size: 28px;
  display: flex;
  justify-content: center;
}

.bottom-box {
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

</style>