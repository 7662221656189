<template>
  <div>

  </div>
</template>

<script>
import {Toast} from "vant";
import {Crisp} from "crisp-sdk-web";
import NProgress from "nprogress";

export default {
  data() {
    return {
      token: '',
      email: '',
      usedTraffic: '',
      allTraffic: '',
      expired: '',
      plan: ''
    }
  },
  created() {
    NProgress.start();
    let token = this.$cookies.get('token')
    if (token != null) {
      this.token = token
      this.cookieSetValue()
      this.crisp()
    } else {
      token = this.$route.query.token
      if (token != undefined) {
        this.token = token
        this.getUserInfo()
      }
    }
  },
  methods: {
    cookieSetValue() {
      this.email = this.$cookies.get("email");
      this.allTraffic = this.$cookies.get("AllTraffic");
      this.usedTraffic = this.$cookies.get("UsedTraffic");
      this.expired = this.$cookies.get("Expired");
      this.plan = this.$cookies.get("Plan");
    },
    crisp() {
      Crisp.configure('15d569e3-d672-4d30-9ba1-33866ad12860', {
        lockMaximized: true,
        lockFullview: true
      });
      Crisp.user.setEmail(this.email);
      Crisp.session.setData({
        AllTraffic: this.allTraffic,
        UsedTraffic: this.usedTraffic,
        Expired: this.expired,
        Plan: this.plan
      })
      Crisp.load();
    },

    getUserInfo() {
      const this_vue = this
      this.$axios.get('user/getSubscribe', {
        headers: {'Authorization': this.token}
      })
          .then(function (resp) {
            this_vue.email = resp.data.data.email
            this_vue.plan = resp.data.data.plan.name
            this_vue.expired = resp.data.data.expired_at
            this_vue.allTraffic = this_vue.bytesToSize(resp.data.data.transfer_enable)
            this_vue.usedTraffic = this_vue.bytesToSize(resp.data.data.u + resp.data.data.d)
            this_vue.crisp()
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },
    showError(error) {
      if (typeof (error.response) == 'undefined')
        Toast.fail(error.message)
      else
        Toast.fail(error.response.data.message)
    },
    bytesToSize(bytes) {
      if (bytes === 0) return '0 B';
      const k = 1024, // or 1024
          sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
    },
  },
  beforeDestroy() {
    NProgress.done();
  }
}
</script>

<style scoped>

</style>
