<template>
  <div class="container">
    <!--   header   -->
    <div class="card">
      <van-row>
        <van-col class="col-left">Email :</van-col>
        <van-col span="16" class="col-right"><span>{{ email }}</span></van-col>
      </van-row>
      <van-row>
        <van-col class="col-left">Current plan :</van-col>
        <van-col span="16" class="col-right">{{ planName }}</van-col>
      </van-row>
      <van-row style="margin-top: 10px" v-show="showPortal">
        <van-button round color="#ff561a" size="small" @click="billingClick">Billing management</van-button>
      </van-row>
    </div>

    <van-row class="card-plan">
      <!--  Plans  -->
      <van-collapse v-model="activePlanNames" accordion style="padding: 5px" :border="false">
        <van-collapse-item title="PLANS" name="PLANS">
          <van-radio-group v-model="planID">
            <van-cell-group :border="border">
              <van-cell v-for="(plan,index) in plans" :key="index" style="margin: 10px 0;padding: 10px 0">
                <template>
                  <van-radio :name="plan.id" style="align-items: flex-start" @click="planClick">
                    <template #icon="props">
                      <img class="radio-icon" :src="props.checked ? checkIcon : uncheckIcon"/>
                    </template>
                    <template>
                      <div style="margin-left: 10px">
                        <van-row type="flex" justify="space-between">
                          <van-col><span class="section-desc-title">{{ plan.name }}</span></van-col>
                          <van-col>
                          <span class="section-desc-title" style="margin-left: 8px " block>
                            ${{ cent2dollar(getPriceSuffix(plan)[0]) }}/{{ getPriceSuffix(plan)[1] }}</span>
                          </van-col>
                        </van-row>
                        <van-row>
                          <span class="section-desc-desc">{{ tojson(plan.content).desc }}</span>
                        </van-row>
                      </div>
                    </template>
                  </van-radio>
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </van-collapse-item>
      </van-collapse>
      <!--    select plan          -->
      <van-row class="select-plan-card " v-if="activePlanNames === '' && currentPlan!=null">
        <van-radio-group v-model="currentPlanName">
          <van-radio v-bind="currentPlan" name="current"
                     style="align-items: flex-start">
            <template #icon="props">
              <img class="radio-icon" :src="props.checked ? checkIcon : uncheckIcon"/>
            </template>
            <template>
              <div style="margin-left: 10px">
                <van-row type="flex" justify="space-between">
                  <van-col><span class="section-desc-title">{{ currentPlan.name }}</span></van-col>
                  <van-col>
                          <span class="section-desc-title" style="margin-left: 8px " block>
                            ${{ cent2dollar(getPriceSuffix(currentPlan)[0]) }}/{{ getPriceSuffix(currentPlan)[1] }}
                          </span>
                  </van-col>
                </van-row>
                <van-row>
                  <span class="section-desc-desc">{{ tojson(currentPlan.content).desc }}</span>
                </van-row>
              </div>
            </template>
          </van-radio>
        </van-radio-group>
      </van-row>
    </van-row>

    <!-- Payments   -->
    <div style="margin-bottom: 40px">
      <van-row class="section-title">PAYMENT METHODS</van-row>
      <div>
        <van-radio-group v-model="paymentID">
          <van-cell-group inset :border="border">
            <van-cell v-for="(payment,index) in payments" :key="index" style="margin: 10px 0">
              <template>
                <van-radio :name="payment.id">
                  <template #icon="props">
                    <img class="radio-icon" :src="props.checked ? checkIcon : uncheckIcon"/>
                  </template>
                  <template>
                    <div style="margin-left: 10px">
                      <span class="section-desc-title">{{ payment.name }}</span>
                    </div>
                  </template>
                </van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </div>

    <!--  checkout  -->
    <div class="bottom-box">
      <van-cell-group inset :border="border">
        <van-button block @click="checkout" class="confirm">Checkout</van-button>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";

export default {
  name: "PaymentPage",
  data() {
    return {
      activePlanNames: 'PLANS',
      currentPlanName: 'current',
      currentPlan: {},
      portalUrl: '',
      checkedColor: '#f3f1f1',
      checkIcon: require("../assets/plan_check.png"),
      uncheckIcon: require("../assets/plan_uncheck.png"),
      border: false,
      email: '',
      uuid: '',
      usedTraffic: '',
      planName: '',
      appPlatform: '',
      token: '',//test
      planID: 0,
      paymentID: 6,
      plans: [],
      payments: [
        {
          name: "Debit/Credit Card",
          id: 6
        },
        {
          name: "Alipay/WeChat Pay",
          id: 8
        }
      ]
    }
  },
  created() {
    let token = this.$cookies.get('token')
    if (token != null)
      this.token = token
    token = this.$route.query.token
    if (token != undefined) {
      this.token = token
    }
    const planid = this.$route.query.planid
    if (planid != undefined) {
      this.planID = planid
    }

    this.appPlatform = this.appSource()
    this.getPortal()
    this.getUrlParams()
    this.getUserInfo()
    this.getPlans()
  },
  methods: {
    appSource() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        return "ios";
      } else {
        return "andriod";
      }
    },
    getUrlParams() {
      const urlParams = this.$route.query;
      const sessionId = urlParams.session_id
      if (typeof (sessionId) != 'undefined' && sessionId != null) {
        // payment success
        if (this.appPlatform == 'ios') {
          window.webkit.messageHandlers.paymentSuccess.postMessage(sessionId);
        } else {
          // eslint-disable-next-line no-undef
          $App.paymentSuccess(sessionId)
        }
      }
    },
    getCurrentPlan() {
      return this.plans.find(item => item.id == this.planID)
    },
    tojson(v) {
      return eval("(" + v + ")")
    },
    async getUserInfo() {
      const this_vue = this
      this.$axios.get('user/getSubscribe', {
        headers: {'Authorization': this.token}
      })
          .then(function (resp) {
            this_vue.email = resp.data.data.email
            this_vue.uuid = resp.data.data.uuid
            this_vue.planName = resp.data.data.plan.name
            this_vue.usedTraffic = this_vue.bytesToSize(resp.data.data.u + resp.data.data.d)
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },
    cent2dollar(num) {
      if (typeof num !== "number" || isNaN(num)) return null;
      return (num / 100).toFixed(2);
    },
    async getPortal() {
      const this_vue = this
      this.$axios.get('user/getportal', {headers: {'Authorization': this.token}})
          .then(function (resp) {
            if (resp.data.code == 0) {
              this_vue.portalUrl = resp.data.url
            }
            console.log(resp.data)
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },
    async getPlans() {
      const this_vue = this
      this.$axios.get('user/plan/fetch',
          {
            // params: {'nonew': 1},
            headers: {'Authorization': this.token}
          })
          .then(function (resp) {
            this_vue.plans = resp.data.data
            this_vue.currentPlan = this_vue.getCurrentPlan()
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },
    billingClick() {
      window.open(this.portalUrl, '_self')
    },
    checkout() {
      if (this.planID === 0) {
        Toast.fail('Please select a plan')
        return
      }
      if (this.paymentID === '') {
        Toast.fail('Please select a Payment')
        return
      }
      const plan = this.plans.find(item => item.id == this.planID)
      if (typeof (plan) == 'undefined') {
        Toast.fail('Unable to obtain plan information')
        return
      }
      const period = this.getPeriod(plan)
      const content = this.tojson(plan.content)

      Toast.loading({
        duration: 0,
        forbidClick: true,
      })

      if (this.paymentID === 6) {
        // stripe
        if (typeof (content.stripe) == 'undefined') {
          Toast.fail('Stripe not supported')
          return
        }
        this.stripePay(content.stripe, period)
      } else if (this.paymentID === 8) {
        // alipay
        this.ePay1(period);
      }


    },
    stripePay(token, period) {
      const this_vue = this
      this.$axios.post('user/order/stripeCheckout', {
        email: this.email,
        token: token,
        uuid: this.uuid,
        plan_id: this.planID,
        period: period,
        method: this.paymentID
      }, {headers: {'Authorization': this.token}})
          .then(function (resp) {
            console.log(resp)
            const url = resp.data.data
            const surl = new URL(url)
            surl.host = "checkout.stripe.com"
            window.open(surl.href, '_self')
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },
    ePay1(period) {
      const this_vue = this
      this.$axios.post('user/order/save', {
        period: period,
        plan_id: this.planID,
        type: 1,
      }, {headers: {'Authorization': this.token}})
          .then(function (resp) {
            console.log(resp)
            const tradeNo = resp.data.data
            this_vue.ePay2(tradeNo)
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },
    ePay2(tradeNo) {
      const this_vue = this
      this.$axios.post('user/order/checkout', {
        method: this.paymentID,
        trade_no: tradeNo,

      }, {headers: {'Authorization': this.token}})
          .then(function (resp) {
            console.log(resp)
            const url = resp.data.data
            window.open(url, '_self')
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },

    getPeriod(plan) {
      if (plan.month_price) {
        return 'month_price'
      } else if (plan.quarter_price) {
        return 'quarter_price'
      } else if (plan.half_year_price) {
        return 'half_year_price'
      } else if (plan.year_price) {
        return 'year_price'
      }
    },
    getPriceSuffix(plan) {
      let price = 0;
      let suffix = '';
      if (plan.month_price != null) {
        price = plan.month_price
        suffix = 'mo.';
        return [price, suffix]
      } else if (plan.year_price != null) {
        price = plan.year_price
        suffix = 'yr.'
        return [price, suffix]
      } else if (plan.half_year_price != null) {
        price = plan.half_year_price
        suffix = 'hy.'
        return [price, suffix]
      }
    },
    showError(error) {
      if (typeof (error.response) == 'undefined')
        Toast.fail(error.message)
      else
        Toast.fail(error.response.data.message)
    },
    bytesToSize(bytes) {
      if (bytes === 0) return '0 B';
      const k = 1024, // or 1024
          sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
    },
    planClick() {
      this.activePlanNames = ''
      this.currentPlan = this.getCurrentPlan()
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#F8F8FA;')
  },
  computed: {
    showPortal() {
      return this.portalUrl.length > 0
    }
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  }
}
</script>

<style scoped>
.container {
  padding: 30px 0px;
}

span {
  white-space: pre-line;
}

.radio-icon {
  width: 20px;
  height: 20px;
}

.section-title {
  font-size: 13px;
  line-height: 18px;
  margin: 10px 35px;
  color: #151B2599;
}

.section-desc-title {
  font-size: 17px;
  line-height: 22px;
  color: #151B25;
}

.section-desc-desc {
  font-size: 12px;
  line-height: 18px;
  color: #B7BFC6;
}

.card {
  margin: 15px 15px;
  padding: 10px 24px;
  background-color: #fff;
  border-radius: 12px;
}

.card-plan {
  margin: 0px 15px;
  background-color: #fff;
  border-radius: 12px;
}

.select-plan-card {
  padding: 10px 24px;
  background-color: #fff;
  border-radius: 12px;
}


.col-left {
  font-size: 15px;
  line-height: 22px;
  color: #151B25;
}

.col-right {
  padding-left: 10px;
  font-size: 13px;
  line-height: 22px;
  color: #B7BFC6;
}

.bottom-box {
  background: #F8F8FA;
  position: fixed;
  bottom: 10px;
  width: 100%;
}

.confirm {
  background: #ff561a;
  color: white;
  font-weight: bold;
  border-radius: 6px;
}

.van-radio >>> .van-radio__label {
  width: 100%;
}
</style>
