import { render, staticRenderFns } from "./CheckoutPage.vue?vue&type=template&id=ec545f6e&scoped=true"
import script from "./CheckoutPage.vue?vue&type=script&lang=js"
export * from "./CheckoutPage.vue?vue&type=script&lang=js"
import style0 from "./CheckoutPage.vue?vue&type=style&index=0&id=ec545f6e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec545f6e",
  null
  
)

export default component.exports