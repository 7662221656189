import Vue from "vue"
import VueRouter from 'vue-router'
import Chat from "../components/CChat";
import Payments from "../components/PaymentPage";
import CheckOut from "../components/CheckoutPage";
import Affiliation from "../components/AffiliationPage";

Vue.use(VueRouter)

const routers = [
    {
        path: '/chat',
        component: Chat,
        meta: {
            title: 'Bayria Support',
        }
    },
    {
        path: '/payments',
        component: Payments,
        meta: {
            title: 'Bayria Payments'
        }
    },
    {
        path: '/checkout',
        component: CheckOut,
        name: 'checkout',
        meta: {
            title: 'Checkout'
        }
    },
    {
        path: '/affiliation',
        component: Affiliation,
        name: 'affiliation',
        meta: {
            title: 'Affiliation'
        }
    }
]
const router = new VueRouter({
    mode: 'history',
    routes: routers
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
export default router