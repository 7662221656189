<template>
  <main>
    <van-row>
      <h4 class="title">Affiliation</h4>
    </van-row>
    <van-row style="margin-top: 6px">
      <van-cell-group inset>
        <van-cell title="Email" :value="userInfo.email" title-class="cell-title" value-class="cell-value"/>
        <van-cell title="Signups" :value="inviteInfo.stat[0]" title-class="cell-title"
                  value-class="cell-value"/>
        <van-cell title="Commission rate" :value="getRate" title-class="cell-title"
                  value-class="cell-value"/>

        <van-cell :value="PendingCommission" title-class="cell-title"
                  value-class="cell-value">
          <template #title>
            <span class="cell-title">Pending Commissions</span>
            <span class="help-sapn" @click="showHelp('Pending Commissions','Pending commissions are the result of recent orders awaiting the completion of maturity periods before they become available for withdrawal. We employ a 180-day refund period to ensure our users’ complete satisfaction with our service prior to commission disbursement.<br><br>'+
'Please note that your commission will be accessible 180 days after the initial purchase.','52%')">
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    fill="#287eef"
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
            </span>
          </template>
        </van-cell>

        <van-cell :value="CurrentCommission" title-class="cell-title"
                  value-class="cell-value">
          <template #title>
            <span class="cell-title">Available to Withdraw</span>
            <span class="help-sapn"
                  @click="showHelp('Available to Withdraw',
                  'Available commissions refer to commissions that have surpassed the 180-day pending period and can now be withdrawn.<br><br> It’s important to keep in mind that while there is no minimum account balance requirement to keep your account active, you must meet the minimum withdrawal threshold of $100 in order to fully withdraw your commission. ',
                  '52%')">
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    fill="#287eef"
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
            </span>
          </template>
        </van-cell>

        <van-cell :value="TotalWithdraw" title-class="cell-title"
                  value-class="cell-value">
          <template #title>
            <span class="cell-title">Total Amount Withdrawn</span>
            <span class="help-sapn"
                  @click="showHelp('Total Amount Withdrawn',
                  'The Total Withdrawn Amount represents the sum of funds you have withdrawn since your initial enrollment in our Affiliate program.',
                  '30%')">
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    fill="#287eef"
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
            </span>
          </template>
        </van-cell>

        <van-cell title-class="cell-action-title" @click="WithdrawalDialog">
          <template #title>
            <span style="display: flex;justify-content: center">Withdraw</span>
          </template>
        </van-cell>
      </van-cell-group>
    </van-row>

    <van-row style="margin-top: 32px">
      <van-cell-group inset title="Referral System">
        <van-cell v-for="(item,index) in inviteInfo.codes" :key="index" title="Referral Code" :value="item.code"
                  title-class="cell-title" value-class="cell-value" @click="copyToClipboard(item.code)"/>
      </van-cell-group>
    </van-row>

    <van-row style="margin-top: 32px">
      <van-cell-group inset title="Commissions History">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="No more"
            @load="getCommissions">
          <van-cell v-for="(item,index) in commissions" :key="index" :title="item.trade_no"
                    :value="convertToYuan(item.commission_balance)"
                    title-class="cell-hisotry-title"
                    value-class="cell-history-value">
            <template #label>
              <van-tag :type="commissionStatusStyle(item.commission_status)" style=" margin-right: 10px">
                {{ commissionStatus(item.commission_status) }}
              </van-tag>
              <span class="cell-history-label">{{ convert2USTime(item.paid_at) }}</span>
            </template>
          </van-cell>
        </van-list>
      </van-cell-group>
    </van-row>

    <!--  payout  -->
    <van-dialog v-model="WithdrawalShow" title="Commission Withdrawal" show-cancel-button
                :confirm-button-color="getConfirmButtonCorlor"
                confirmButtonText="Confirm" cancelButtonText="Cancel" @confirm="Withdrawal">
      <van-row style="margin: 10px"/>
      <p style="margin: 0px 20px;;color: red;font-size: 12px;" v-html="this.CurrentWithdraw"/>
      <van-cell-group inset title="Withdrawal Method">
        <van-cell :value="payMethond" is-link @click="methodshow=true" arrow-direction="down" class="cell-border"/>
      </van-cell-group>
      <van-cell-group inset title="Withdrawal Account">
        <van-field v-model="account" placeholder="Please enter the withdrawal account" class="cell-border"/>
      </van-cell-group>
      <van-row style="margin: 20px"/>
    </van-dialog>

    <!--  pay method  -->
    <van-action-sheet v-model="methodshow" :actions="actions" @select="onSelect"/>

    <!--  hlep mark  -->
    <van-popup v-model="helpShow" closeable position="bottom" height="auto">
      <van-row>
        <h7 class="help-title">{{ this.helpTitle }}</h7>
      </van-row>
      <span class="help-content" v-html="this.helpContent"/>
    </van-popup>
  </main>
</template>


<script>


import {Toast} from "vant";

export default {
  name: "AffiliationPage",

  data() {
    return {
      popHeight: '30%',
      loading: false,
      finished: false,
      helpShow: false,
      helpTitle: '',
      helpContent: '',
      account: '',
      helpIcon: require("../assets/affiliation_help.png"),
      payMethond: 'Paypal',
      WithdrawalShow: false,
      methodshow: false,
      actions: [],
      userInfo: {},
      commissions: [],
      inviteInfo: {
        codes: [],
        stat: [],
      },
      currentPage: 0,
      withdrawLimit: '',
      token: ''
    }
  },
  created() {
    const token = this.$cookies.get('token')
    if (token != null)
      this.token = token
    this.getConfig()
    this.getInvite()
    this.getUserInfo()
    this.getInviteDetail()
  },
  computed: {
    getRate() {
      return this.inviteInfo.stat[3] + "%"
    },
    CurrentCommission() {
      return this.convertToYuan(this.inviteInfo.stat[4]);
    },
    PendingCommission() {
      return this.convertToYuan(this.inviteInfo.stat[2]);
    },
    TotalWithdraw() {
      let r = this.inviteInfo.stat[1] - this.inviteInfo.stat[4];
      if (r > 0)
        return this.convertToYuan(r);
      else
        return '$0'
    },
    CurrentWithdraw() {
      return 'Withdraw Minimum: $' + this.withdrawLimit + ' <br><br>Current Balance: ' + this.convertToYuan(this.inviteInfo.stat[4]);
    },
    getConfirmButtonCorlor() {
      if (parseInt(this.withdrawLimit) > this.inviteInfo.stat[4])
        return '#000'
      else
        return '#ebedf0'
    }
  },
  methods: {
    getConfig() {
      const this_vue = this;
      this.$axios.get('guest/comm/config')
          .then(function (resp) {
            console.log(resp.data.data)
            this_vue.withdrawLimit = resp.data.data.commission_withdraw_limit
            this_vue.actions = resp.data.data.commission_withdraw_method.map(item => {
              return {name: item}
            })
          })
          .catch(function (resp) {
            console.log(resp)
          })
    },
    Withdrawal() {
      if (this.account == '') {
        Toast({message: 'Account cannot be empty', duration: 3000})
        return
      }
      if (this.inviteInfo.stat[4] === undefined || parseInt(this.withdrawLimit) > this.inviteInfo.stat[4]) {
        Toast({message: 'Withdrawal is as low as $100', duration: 3000})
        return;
      }

      let toast = Toast.loading({message: 'loading....', forbidClick: true})
      const this_vue = this
      this.$axios.post('user/ticket/withdraw',
          {
            withdraw_account: this.account,
            withdraw_method: this.payMethond
          },
          {
            headers: {'Authorization': this.token, 'Content-type': 'application/x-www-form-urlencoded'}
          })
          .then(function () {
            toast.clear()
            Toast({
              message: 'Withdrawal application successful, Please wait for the administrator to process the order',
              duration: 3000
            })
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },
    WithdrawalDialog() {
      this.WithdrawalShow = true
    },
    getCommissions() {
      const this_vue = this
      this.loading = true
      this.finished = false
      this.$axios.get('user/order/fetchCommission?pageSize=10&current=' + this.currentPage, {headers: {'Authorization': this.token}})
          .then(function (resp) {
            this_vue.loading = false
            this_vue.commissions = [...this_vue.commissions, ...resp.data.data]
            if (this_vue.commissions.length > 10)
              this_vue.currentPage += 1
            this_vue.finished = true

            console.log(resp)
          })
          .catch(function (error) {
            this_vue.loading = false
            this_vue.finished = true
            console.log(error)
            this_vue.showError(error)
          })
    },
    getUserInfo() {
      const this_vue = this
      this.$axios.get('user/getSubscribe', {headers: {'Authorization': this.token}})
          .then(function (resp) {
            this_vue.userInfo = resp.data.data
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },
    getInvite() {
      const this_vue = this
      this.$axios.get('user/invite/fetch', {headers: {'Authorization': this.token}})
          .then(function (resp) {
            this_vue.inviteInfo = resp.data.data
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },
    getInviteDetail() {
      const this_vue = this
      this.$axios.get('user/invite/details', {headers: {'Authorization': this.token}})
          .then(function (resp) {
            console.log(resp)
          })
          .catch(function (error) {
            console.log(error)
            this_vue.showError(error)
          })
    },
    copyToClipboard(s) {
      this.$copyText(s)
      this.showMsg('Copy successful')
    },
    showError(error) {
      if (typeof (error.response) == 'undefined')
        Toast.fail({message: error.message, duration: 3000})
      else
        Toast.fail({message: error.response.data.message, duration: 3000})
    },
    onSelect(value) {
      this.payMethond = value.name;
      this.methodshow = false;
    },
    showMsg(msg) {
      Toast({message: msg, duration: 500})
    },
    convertToYuan(amountInCents) {
      if (amountInCents === undefined)
        return "$0"
      return "$" + (amountInCents / 100).toFixed(2);
    },
    convert2USTime(timestamp) {
      const date = new Date(timestamp * 1000);
      const formattedDate = date.toLocaleDateString('en-US'); // 格式化为美国日期格式 (MM/DD/YYYY)
      return formattedDate
    },
    commissionStatus(status) {
      if (status === 0) {
        return "Pending"
      } else if (status === 1) {
        return "Pending"
      } else if (status === 2) {
        return "Confirmed"
      }
    },
    commissionStatusStyle(status) {
      if (status === 0) {
        return "warning "
      } else if (status === 1) {
        return "warning"
      } else if (status === 2) {
        return "success"
      }
    },
    showHelp(title, content, height) {
      this.helpTitle = title
      this.helpContent = content
      this.popHeight = height
      this.helpShow = true
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#F8F8FA;')
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  }
}

</script>

<style scoped>
.title {
  display: flex;
  justify-content: center;

}

.cell-border {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin: 0px 2px;
  width: 98%;
}

.cell-title {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 400;
  color: #151B25;
  line-height: 22px;
}

.cell-value {
  font-size: 17px;
  color: #151B2599;
  flex: 0 0 auto;
}

.cell-action-title {
  color: #2E90FA;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
}

.cell-history-title {
  color: #151B25;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}

.cell-history-value {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  align-self: center;
}

.cell-history-label {
  color: #151B2599;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
}

.paymeth-title {
  margin: 0;
  padding: 32px 16px 16px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.help-sapn {
  margin-left: 5px;
  margin-top: 5px;
}

.help-title {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  font-size: 17pt;
  line-height: 22pt;
}

.help-content {
  margin: 15px 50px 40px;
  display: flex;
  justify-content: center;
  font-size: 13pt;
  line-height: 16pt;
}

</style>